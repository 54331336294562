import Button from "react-bootstrap/Button";
import { LeftArrow, RightArrow } from "../../staticfiles/svgPack";
import { ENTER_KEY_NAME, PAGE_SIZE } from "../../Config/config";
import { useEffect, useState } from "react";
import regx from "../../staticfiles/regex";

const Pagination = ({ count, loading, setPaginationNo, currentPage }) => {
  const [foused, setFocused] = useState(true);
  const [currentPageValue, setCurrentPageValue] = useState(currentPage);
  //how many pages total: total no.of pages = actual count / page size(how many results will fit in one page)
  const pageCount = Math.ceil(count / PAGE_SIZE);
  const handleChange = (e) => {
    const { value } = e.target;

    // preventing the user from typing string=> if the user is typing a non numeric number it will return
    if (!regx.isNumeric.test(value)) {
      return;
    }

    // Enter functionality
    if (e.key === ENTER_KEY_NAME) {
      handleNextPage(value);
      return;
    }

    if (value > pageCount) {
      // if the user type value greater than page count the value will be page count
      setCurrentPageValue(pageCount);
    } else {
      setCurrentPageValue(value);
    }
  };

  const handlePrevPage = () => {
    const prev = currentPage === 1 ? currentPage : currentPage - 1;
    setPaginationNo(prev);
  };

  const handleNextPage = (value) => {
    let next;

    if (value === "") {
      // If the value is an empty string
      next = 1; // assign 1 as value
    } else if (value !== undefined) {
      // If the value is defined and not an empty string
      next = value;
    } else {
      // If the value is undefined
      next = currentPage === pageCount ? currentPage : currentPage + 1;
    }
    // Assuming searchParams is already defined before this function is called
    setPaginationNo(next);
  };

  useEffect(() => {
    setCurrentPageValue(currentPage);
  }, [currentPage]);

  //if the page count is less than 1 => i won't show the pagination component
  if (pageCount <= 1) return null;
  return (
    <div className="d-flex justify-content-end mt-5 fw-600">
      <div className="text-right">
        Page
        {foused ? (
          <input
            variant="outline-secondary"
            className="px-3 aspect-square text-black ms-3 paginationInput"
            value={currentPage}
            onChange={handleChange}
            onFocus={() => setFocused(false)}
          />
        ) : (
          <input
            variant="outline-secondary"
            className="px-3 aspect-square text-black ms-3 paginationInput"
            value={currentPageValue}
            onChange={handleChange}
            onBlur={() => handleNextPage(currentPageValue)}
            onKeyDown={handleChange}
          />
        )}
        <span className="mx-3">of {pageCount}</span>
        {/*Previous Page Button */}
        <Button
          variant="outline-dark"
          className="ps-4 pe-4 min-w-150px me-3"
          onClick={handlePrevPage}
          disabled={currentPage === 1 || loading}
        >
          <span>
            <LeftArrow />
          </span>
          <span className="ms-3">Previous Page</span>
        </Button>
        {/* Next Page Button */}
        <Button
          variant="outline-dark"
          className="ps-4 pe-4 min-w-150px"
          onClick={() => handleNextPage()}
          disabled={currentPage === pageCount || loading}
        >
          <span>Next Page</span>
          <span className="ms-3">
            <RightArrow />
          </span>
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
