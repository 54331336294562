import {
  AccountCircle,
  Assignment,
  AwardStarIcon,
  DOllarIcon,
  DashboardIcon,
  FactCheck,
  FeaturedVideo,
  HandIcon,
} from "../staticfiles/svgPack";

const NAV_LINKS = [
  { to: "/dashboard", title: "Dashboard", icons: <DashboardIcon /> },
  { to: "/profile", title: "Profiles", icons: <AccountCircle /> },
  { to: "/verified-agent", title: "Verified", icons: <FactCheck /> },
  { to: "/advertisement", title: "Advertisements", icons: <FeaturedVideo /> },
  {
    to: "/tenant-questions",
    title: "Tenant Form Questions",
    icons: <Assignment />,
  },
  { to: "/prizes", title: "Giveaway Prizes", icons: <AwardStarIcon /> },
  { to: "/rently-deals", title: "Deals", icons: <HandIcon /> },
  { to: "/rently-pro", title: "RentlyPro", icons: <DOllarIcon /> },
];

export const EDIT_DETAILS = {
  isEditGmail: false,
  isEditPassword: false,
};

export const customFormError = (setError, fieldName, errorMessage) => {
  setError(fieldName, {
    type: "manual",
    message: errorMessage,
  });
};

export const IMAGE_FORMAT_ALLOWED = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/webp",
];

//image validation
export const ImageValidation = (file, type, checkAllowed = true) => {
  //allowed types

  const Types = type;
  if (checkAllowed && !Types.includes(file?.type)) return false;

  //if  checkAllowed is false => it will check this condition , and also check the file type with function parameter type array elements. if array contain contain that format => will throw error
  // and also checking the file type if start with video .. if => return false & throw error
  if (
    !checkAllowed &&
    (Types.includes(file.type) || file?.type.startsWith("video"))
  )
    return false;

  return true;
};

export const PAGE_SIZE = 10;

export const IMAGE_MAX_SIZE = 5120; //5MB (1*1024kb) for 1mb
export default NAV_LINKS;

export const DEVICETYPE = {
  DESKTOP: "DESKTOP",
  MOBILE: "MOBILE",
};

export const DESKTOP_1_RESOLUTION = "Dashboard 1 - Desktop (546x301)";
export const DESKTOP_2_RESOLUTION = "Dashboard 2 - Desktop (546x247)";

export function convertToMonthYear(dateStrings) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return dateStrings.map((dateString) => {
    const originalDate = new Date(dateString);
    const month = monthNames[originalDate.getUTCMonth()];
    const year = originalDate.getUTCFullYear();
    return `${month} ${year}`;
  });
}

export const EDIT_COMPANY_ACTIVE = {
  name: false,
  url: false,
};

export const PERSONAL_EVENT_KEY = 0;
export const FINANCIAL_EVENT_KEY = 1;
export const CREDIT_EVENT_KEY = 2;
export const EXTRA_EVENT_KEY = 3;
export const GUARANTOR_EVENT_KEY = 4;

export const EMPLOYMENT_STATUS = {
  SELF_EMPLOYEE: "Self-employed",
  DIRECTOR: "Director",
  STUDENT: "Student",
  RETIRED: "Retired",
  UN_EMPLOYEE: "Unemployed",
  EMPLOYEE: "Employee",
};

export const EDIT_PRIZE_ACTIVE = {
  details: false,
  title: false,
};

export const ENTER_KEY_NAME = "Enter";

// REACT- SELECT CUSTOM CONFIG
export const CUSTOM_CONFIG = {
  DropdownIndicator: null, // Remove the default dropdown indicator
  ClearIndicator: null, // Remove the clear indicator
  IndicatorSeparator: null, // Remove the indicator separator
};

export const EDIT_DEAL_ACTIVE = {
  category: false,
  url: false,
};

export const DEALS_FIELD = {
  CATEGORY: "category",
  URL: "url",
};

export const EDIT_RENTLY_PRO_ACTIVE = {
  name: false,
  pEmail: false,
  region: false,
};

export const RENTLY_PRO_DEALS_FIELD = {
  BRANCH_NAME: "name",
  PRIMARY_EMAIL: "pEmail",
  REGION: "region",
};

export const INITIAL_CATEGORY_VALUE = [{ label: "" }];

export const EDIT_BRANCH_DETAILS = {
  costPerEmail: false,
  costPerText: false,
  costPerProperty: false,
  primaryContact: false,
  pEmail: false,
  pNumber: false,
};

export const EDIT_BRANCH_DETAILS_FIELD = {
  COST_PER_EMAIL: "costPerEmail",
  COST_PER_TEXT: "costPerText",
  COST_PER_PROPERTY: "costPerProperty",
  PRIMARY_CONTACT: "primaryContact",
  PRIMARY_CONTACT_MAIL: "pEmail",
  PRIMARY_CONTACT_NUMBER: "pNumber",
  COST_PER_MONTH: "costPerMonth",
};

export const BRANCH_ROLE_OPTIONS = [
  { value: "Agent", label: "Agent" },
  { value: "BranchManager", label: "Branch Manager" },
];

export const ROLE = {
  BRANCH_MANGER: "BranchManager",
  AGENT: "Agent",
};

export const OPTION_ROLE = [
  { value: "Agent", label: "Agent" },
  { value: "BranchManager", label: "Branch Manager" },
];