import imagePack from "../staticfiles/imagePack";
const ProfilePath = import.meta.env.VITE__APP_PROFILE_PATH;
export const handleApiError = (err) => {
  console.log(err.message);
  let message = "";
  if (err.response) {
    if (err.response.data.errors) {
      // If the API response contains an 'errors' array
      const error = err.response.data.errors[0];
      const message = error.msg || "An error occurred"; // Use the error message or a generic message
      return message;
    } else if (err.response.data.message) {
      message = err.response.data.message;
      return message;
    } else if (err.response.status == 401) {
      message = "Your session has expired. Please log in again";
      return message;
    } else {
      message = err.message;
      return message;
    }
  } else {
    return err;
  }
};

export const getEnvPath = (type, fileName) => {
  switch (type) {
    case "profilePic":
      return fileName ? ProfilePath + fileName : imagePack.blankProfile;
    case "companyProfilePic":
      return fileName ? ProfilePath + fileName : imagePack.defaultRentlyIcon;
    case "compressed-profilePic":
      return fileName
        ? ProfilePath + `${fileName.split(".")[0]}-compressed.jpeg`
        : imagePack.blankProfile;
    case "agentPendingFiles":
      return fileName ? ProfilePath + fileName : imagePack.blankProfile;
    default:
      return imagePack.blankProfile;
  }
};

export const customFormError = (setError, fieldName, errorMessage) => {
  setError(fieldName, {
    type: "manual",
    message: errorMessage,
  });
};

export function formatTimestamp(timestampStr) {
  const timestamp = new Date(timestampStr);
  const formattedTimestamp = timestamp.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    day: "numeric",
    month: "numeric",
    year: "2-digit",
  });

  return formattedTimestamp;
}

export const ImageValidation_size = (file, type, MAX_SIZE) => {
  //allowed types
  const Types = type;
  if (!Types.includes(file?.type)) return false;

  if (MAX_SIZE) {
    const fileSizeKilobytes = file?.size / 1024; //("1mb")
    //if file size greater than or equal to 5mb it wil return false and display error
    if (fileSizeKilobytes > MAX_SIZE) return false;
  }

  return true;
};

export const formatTimestamp_ = (originalTimestamp) => {
  // Parse the original timestamp
  const parsedTimestamp = new Date(originalTimestamp);

  // Format the parsed timestamp
  const formattedTimestamp = `${parsedTimestamp.getHours() % 12 || 12}:${String(
    parsedTimestamp.getMinutes()
  ).padStart(2, "0")} ${
    parsedTimestamp.getHours() >= 12 ? "pm" : "am"
  } ${String(parsedTimestamp.getDate()).padStart(2, "0")}/${String(
    parsedTimestamp.getMonth() + 1
  ).padStart(2, "0")}/${String(parsedTimestamp.getFullYear()).slice(2)}`;

  return formattedTimestamp;
};

export function capitalizeSentence(sentence) {
  return sentence.replace(/(^|\.\s+|\n)([a-z])/g, function (match, p1, p2) {
    return p1 + p2.toUpperCase();
  });
}
export const formatDob = (dobString) => {
  const dob = new Date(dobString);

  if (isNaN(dob.getTime())) {
    // Invalid date string, return an empty
    return "";
  }

  const formattedDob = `${dob.getDate()} ${getMonthName(
    dob.getMonth()
  )} ${dob.getFullYear()} (${calculateAge(dob)} years old)`;

  return formattedDob;
};

function getMonthName(month) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[month];
}

export function calculateAge(dob) {
  const today = new Date();
  const dobYear = dob.getFullYear();
  const dobMonth = dob.getMonth();
  const dobDay = dob.getDate();

  const age = today.getFullYear() - dobYear;
  if (
    today.getMonth() < dobMonth ||
    (today.getMonth() === dobMonth && today.getDate() < dobDay)
  ) {
    return age - 1;
  }

  return age;
}

export function convertDateFormat(dateString) {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const calculateAges = (dateOfBirth) => {
  const dob = new Date(dateOfBirth);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - dob.getFullYear();

  if (
    currentDate.getMonth() < dob.getMonth() ||
    (currentDate.getMonth() === dob.getMonth() &&
      currentDate.getDate() < dob.getDate())
  ) {
    age--;
  }

  return age;
};

export const buildFormData = (data, formData, parentKey) => {
  if (
    data &&
    !(Array.isArray(data) && data.length === 0) && //empty array ([]) was considering as object and the condition in next line didn't work and caused problem when sending empty array
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        data[key],
        formData,
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
    //if type is an array without any item ,then remove that array
  } else if (typeof data === "object" && data?.length === 0) {
    //previous condition caused problem when upload image
    / empty /;
  } else {
    const value = data === null ? "" : data;
    formData.append(parentKey, value);
  }
};

export const formatNumberWithDecimal = (value) => {
  if (!value) {
    return;
  }
  const stringValue = value.toString();
  const paddedValue = stringValue.padStart(3, "0");
  const formattedValue = paddedValue.slice(0, -2) + "." + paddedValue.slice(-2);
  return formattedValue;
};

export const addExtraDigits = (value) => {
  // Multiply the value by 100 and convert it to an integer
  const multipliedValue = Math.floor(value * 100);
  return multipliedValue;
};

export const calculateDuration = (startDate) => {
  const start = new Date(startDate);
  const now = new Date();

  let years = now.getFullYear() - start.getFullYear();
  let months = now.getMonth() - start.getMonth();

  if (months < 0) {
    years--;
    months += 12;
  }

  return { years, months };
};

export const pluralizeCount = (count, singular, plural = `${singular}s`) => {
  const rules = new Intl.PluralRules("en-US");
  const grammaticalNumber = rules.select(count);
  const word = grammaticalNumber === "one" ? singular : plural;
  return `${count} ${word}`;
};

export const RenderBedrooms = (data) => {
  if (typeof data === "number") return data;
  if (typeof data === "object" && data?.length) return data.join(",");
  return data;
};
