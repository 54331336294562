import { createSlice } from "@reduxjs/toolkit";

const namespace = "user";
const initialState = {
  tenantData: null,
  tenantCount: null,
  agentData: [],
  agentCount: null,
  singleTenantData: null,
  HouseHoldData: null,
  singleHouseHold: null,
};

const getAllUserSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setAllTenants: (state, action) => {
      state.tenantData = action.payload;
    },
    setTenantsCount: (state, action) => {
      state.tenantCount = action.payload;
    },
    setAllAgents: (state, action) => {
      state.agentData = action.payload;
    },
    setAgentsCount: (state, action) => {
      state.agentCount = action.payload;
    },
    setTenant: (state, action) => {
      state.singleTenantData = action.payload;
    },
    setHouseHold: (state, action) => {
      state.HouseHoldData = action.payload;
    },
    setSingleHouseHold: (state, action) => {
      state.singleHouseHold = action.payload;
    },
    clearData: () => initialState,
  },
});

export const {
  setAllTenants,
  setAllAgents,
  setTenantsCount,
  setAgentsCount,
  clearData,
  setTenant,
  setHouseHold,
  setSingleHouseHold,
} = getAllUserSlice.actions;
export default getAllUserSlice.reducer;
