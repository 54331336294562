export const apiEndPoints = {
  Agent: { leadDetails: "/leads/details/:leadId", leadsList: "/leads/list" },
  Admin: {
    login: "/user/login",
    verifyOtp:"/user/twoFactor",
    resentOtp:"/user/sendotp",
    forgetPassword: "/user/forgot-password",
    resetPassword: "/user/reset-password",
    changePassword: "/user/update-password",
    verifyUser: "/user/verify-jwt",
    updatePassword: "/user/change-password",
    updateCred: "/user/update-user",
    uploadProfilePictureApi: "/user/upload-profile",
    getTenants: "/tenant/list",
    getAgents: "/agent/list",
    getVerifiedCompany: "/admin/company/list",
    addCompany: "/admin/create-company",
    getAllAd: "/advertisement/list",
    addAd: "/advertisement/create",
    updateAd: "/advertisement/update",
    removerUrl: "/advertisement/reset-url",
    removerImage: "/advertisement/remove-image",
    getGraphData: "/admin/stat",
    getPendingCompany: "/agent/pending-agent",
    removeCompany: "/admin/company/:id",
    declinePendingReview: "/user/:id",
    acceptPendingReview: "/agent/accept-pending-agent",
    deleteUser: "/user/:id",
    editCompanY: "/admin/company",
    removeCompanyLogo: "/admin/remove-company-logo",
    getTenant: "/tenant/profile/:id",
    getMultipleHouseHold: "/household/details/:id",
    deletePrize: "/prize/:id",
    getPrize: "/prize/list",
    createPrize: "/prize/create",
    removeImage: "/prize/remove-image",
    updatePrize: "/prize/update",
    addPrize: "/prize/create",
    getDeals: "/deals/list",
    deleteDeal: "/deals/delete",
    updateDeal: "/deals/update",
    addDeals: "/deals/create",
    getCategory: "/deals/list-categories",
    createCategory: "/deals/create-category",
    deleteCategory: "/deals/category",
    createBranch: "/branch/create",
    deleteBranch: "/branch/:id",
    getBranch: "/branch/list",
    getSingletBranch: "/branch/details/:id",
    updateBranch: "/branch/update",
    createBranchUser: "/invite/create",
    getBranchUser: "/invite/users/:id",
    removeTeamMember: "/branch/delete-user",
    updateRole: "/branch/update/role",
    pauseSubscription:"/branch/billing/pause",
    resumeSubscription:"/branch/billing/resume"
  },
};
